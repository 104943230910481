import img1DChess from '../assets/images/1d-chess.png';
import imgDnd from '../assets/images/dnd-helper.png';
import imgTfMarsHome from '../assets/images/tf-mars-home.png';
import imgXwingGameboard from '../assets/images/xwing-gameboard.png';
// import imgXwingQb from '../assets/images/xwing-quick-build.png';
import imgLiarsDiceOdds from '../assets/images/liars-dice-odds.png';
import imgPetals from '../assets/images/petals.png';
import imgChess from '../assets/images/chess.png';

const sites = [
  {
    name: 'Tools & Automation',
    sites: [
      {
        title: 'Terraforming Mars Helper',
        link: 'http://tf-mars.lloyd.games',
        description: 'Resource tracking for the board game Terraforming Mars',
        image: imgTfMarsHome,
      },
      {
        title: 'X-Wing Card Manager',
        link: 'http://x-wing.lloyd.games',
        description: 'Manage your Star Wars X-Wing cards and game board',
        image: imgXwingGameboard,
      },
      {
        title: 'D&D Shop Helper',
        link: 'http://dnd.lloyd.games',
        description: 'Make a D&D 5e shop and manage your inventory',
        image: imgDnd,
      },
      {
        title: "Liar's Dice Odds",
        link: 'https://mahburg.github.io/liars-dice-odds/',
        description: "Graph of odds for Liar's Dice and dice count tracker",
        image: imgLiarsDiceOdds,
      },
    ],
  },
  {
    name: 'Games',
    sites: [
      {
        title: '1D Chess',
        link: 'http://1d-chess.lloyd.games',
        description: 'Simple chess game with a twist (in progress)',
        image: img1DChess,
      },
      {
        title: 'Chess',
        link: 'http://chess.lloyd.games',
        description: 'Weekend chess board project (not finished)',
        image: imgChess,
      },
    ],
  },
  {
    name: 'Puzzles etc...',
    sites: [
      {
        title: 'Petals Around the Rose',
        link: 'https://mahburg.github.io/petals-around-the-rose/',
        description: 'Generate a random X-Wing Quick Build',
        image: imgPetals,
      },
    ],
  },
];

export default sites;
