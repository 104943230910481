import React from 'react';
import styled from 'styled-components';

function SiteCard({ title, link, description, image }) {
  return (
    <LinkWrapper href={link}>
      <Card>
        <h3>{title}</h3>
        <Description>{description}</Description>
        <Thumbnail src={image} alt={title} />
      </Card>
    </LinkWrapper>
  );
}

const LinkWrapper = styled.a`
  text-decoration: none;
`;

const Card = styled.article`
  border: 1px solid black;
  border-radius: 12px;
  box-shadow: 4px 4px 3px 3px rgba(0, 0, 0, 0.4);
  display: grid;
  /* grid-template-rows: 1fr 2fr 3fr; */
  background-color: #ddd;
  color: black;
  overflow: hidden;
  padding-bottom: 12px;

  h3 {
    text-align: center;
    border-bottom: 1px solid black;
    padding: 0.5rem 0;
    /* margin-bottom: 0.5rem; */
    background-color: #000;
    color: #fff;
    font-size: 1.5rem;
  }

  &:hover {
    transform: scale(1.01);
    transition: all 0.3s ease-in-out;
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.2);

    h3 {
      color: firebrick;
      transition: all 0.3s ease-in-out;
    }
  }
`;

const Description = styled.p`
  padding: 1rem;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  max-height: 250px;
  object-fit: cover;
  object-position: top;
`;

export default SiteCard;
