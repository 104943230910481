import React from 'react';
import styled from 'styled-components';

function SectionHeader(props) {
  return <Container>{props.children}</Container>;
}

const Container = styled.h2`
  grid-column: 1 / -1;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid white;
  padding-bottom: 0.5rem;
  font-size: 2rem;
`;

export default SectionHeader;
